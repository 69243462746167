import React from 'react'
import { graphql } from 'gatsby'
import Seo from 'components/Seo'
import Footer from 'components/Footer'
import * as styles from './styles.module.scss'
import PostTitle from 'blocks/PostTitle'
import Inview from 'components/Inview'

// IMPORTING BLOCKS
// 1) add to imports
// 2) add to components object
// 3) add to graphQL

import PostBody from 'blocks/PostBody'
import PostImage from 'blocks/PostImage'
import PostVideo from 'blocks/PostVideo'

// [name of block type]: [Imported Component]
// ['TextCentered']: TextCentered

const components = {
  PostBody,
  PostImage,
  PostVideo,
}

const ContentType = (props) => {
  const { data, pageContext, layoutProps } = props
  const { locale } = pageContext
  const { page } = data
  const content = page.frontmatter[locale]

  return (
    <>
      <Seo content={content} locale={locale} />
      <PageTemplate
        content={content}
        locale={locale}
        layoutProps={layoutProps}
      />
    </>
  )
}

// from CMS: go directly to Page Template

const PageTemplate = ({ content, locale, layoutProps }) => {
  const { title, blocks } = content
  const postTitleContent = {
    title,
  }
  return (
    <Inview className={`${styles.page} page`}>
      <div className={`${styles.wrap} in in-fade-in delay-0`}>
        <div className='container'>
          <div className={styles.containerInner}>
            <PostTitle content={postTitleContent} />
            {blocks?.map((block, i) => {
              if (components[block.type]) {
                return React.createElement(components[block.type], {
                  content: block,
                  locale,
                  layoutProps,
                  key: i,
                })
              }
            })}
          </div>
        </div>
      </div>
      <Footer locale={locale} />
    </Inview>
  )
}

export default ContentType

export const pageQuery = graphql`
  query ($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        en {
          title
          blocks {
            type
            ...PostTitleEn
            ...PostBodyEn
            ...PostImageEn
            ...PostVideoEn
          }
          seo {
            title
            description
            keywords
          }
        }
        zh {
          title
          blocks {
            type
            ...PostTitleZh
            ...PostBodyZh
            ...PostImageZh
            ...PostVideoZh
          }
          seo {
            title
            description
            keywords
          }
        }
      }
    }
  }
`
