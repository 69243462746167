import React from 'react'
import { graphql } from 'gatsby'
import * as styles from './styles.module.scss'
import MarkdownWrap from 'components/MarkdownWrap'

const Block = ({ content }) => {
  const { body } = content
  return (
    <div className={styles.component}>
      <MarkdownWrap body={body} />
    </div>
  )
}

export default Block

export const fragmentEn = graphql`
  fragment PostBodyEn on MarkdownRemarkFrontmatterEnBlocks {
    body
  }
`
export const fragmentZh = graphql`
  fragment PostBodyZh on MarkdownRemarkFrontmatterZhBlocks {
    body
  }
`
