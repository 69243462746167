import React from 'react'
import { graphql } from 'gatsby'
import * as styles from './styles.module.scss'

const Block = ({ content }) => {
  const { title } = content
  return <h1 className={styles.title}>{title}</h1>
}

export default Block

export const fragmentEn = graphql`
  fragment PostTitleEn on MarkdownRemarkFrontmatterEnBlocks {
    title
  }
`
export const fragmentZh = graphql`
  fragment PostTitleZh on MarkdownRemarkFrontmatterZhBlocks {
    title
  }
`
