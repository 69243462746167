import React from 'react'
import { graphql } from 'gatsby'
import * as styles from './styles.module.scss'
import ImageWrap from 'components/ImageWrap'

const Block = ({ content }) => {
  const { image, caption } = content
  return (
    <div className={styles.component}>
      <ImageWrap image={image} />
      {caption && <figcaption className={styles.caption}>{caption}</figcaption>}
    </div>
  )
}

export default Block

export const fragmentEn = graphql`
  fragment PostImageEn on MarkdownRemarkFrontmatterEnBlocks {
    image {
      childImageSharp {
        gatsbyImageData
      }
    }
    caption
  }
`
export const fragmentZh = graphql`
  fragment PostImageZh on MarkdownRemarkFrontmatterZhBlocks {
    image {
      childImageSharp {
        gatsbyImageData
      }
    }
    caption
  }
`
